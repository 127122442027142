import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import Layout from 'components/Layout';

import useModal from 'hooks/useModal';

import { sendSupixEvent } from 'lib/supix';

import { useGlobalQueries } from 'providers/GlobalQueriesProvider';
import { useUtm } from 'providers/UtmProvider';

import FeaturedOffers from './FeaturedOffers';
import OffersListSection from './OffersListSection';
import Sidebar from './Sidebar';
import WishlistMaintenanceActionSheet from './WishlistMaintenanceActionSheet';

import LINKS from 'constants/links';

const ResetPasswordModal = dynamic(
  () => import('components/ResetPasswordModal'),
  { ssr: false }
);
const SmallAds = dynamic(() => import('components/Timeline/SmallAds'), {
  ssr: true,
});

const GA_EVENT_CATEGORY = 'timeline';
const SMALL_ADS_LENGTH_FOR_RENDER = 4;
const HIGHLIGHTS_OFFERS = 'highlights';

const HomePage = ({
  cardsAds,
  cookieIsUserLogged,
  cookieOffersViewMode = null,
  description = null,
  pageTitle = null,
  recoveryPassword = null,
  serverFeaturedOffers,
  serverOffers,
  sidebarAd,
  smallAds,
}) => {
  const { activeOffersListTab } = useGlobalQueries();
  const { showModal } = useModal();
  const router = useRouter();
  const [isFooterOpen, setFooterOpen] = useState(false);
  const { isUtmLoaded } = useUtm();

  useEffect(() => {
    if (!recoveryPassword) {
      return;
    }

    /**
     * When the query params of recovery password are invalid, we remove these
     * params from the URL without refresh completely the page and add a new
     * state in navigation history
     */
    if (recoveryPassword?.isValid) {
      showModal(ResetPasswordModal, recoveryPassword);
    }

    router.replace(LINKS.HOME, undefined, { shallow: true });
  }, []);

  useEffect(() => {
    if (isUtmLoaded) {
      sendSupixEvent('/car', { category: 0 });
    }
  }, [isUtmLoaded]);

  const onShowFooterButtonClick = useCallback(() => {
    setFooterOpen(true);
  }, []);

  return (
    <>
      <NextSeo
        description={description}
        openGraph={{
          title: pageTitle,
          description,
        }}
        title={pageTitle}
      />
      <Layout
        footerProps={{
          force: isFooterOpen,
          isFixed: true,
          isOpen: isFooterOpen,
          withCash3Section: true,
          onClose: setFooterOpen,
        }}
        gaEventCategory={GA_EVENT_CATEGORY}
        withGridLayout={false}
        maxWidthOnMobileDevices
        scrollToTopButton
      >
        {smallAds.length === SMALL_ADS_LENGTH_FOR_RENDER && (
          <div className="col-span-4 w-full px-4 pt-4 md:col-span-16 lg:px-0">
            <SmallAds
              ads={smallAds}
              className={activeOffersListTab.id !== HIGHLIGHTS_OFFERS && 'mb-4'}
            />
          </div>
        )}
        <div
          className={twJoin(
            'col-span-4 mb-6 w-full px-4 pt-7 md:col-span-16 lg:px-0',
            activeOffersListTab.id !== HIGHLIGHTS_OFFERS && 'hidden md:block'
          )}
          data-test-selector="featured-offers"
        >
          <FeaturedOffers
            gaEventCategory={GA_EVENT_CATEGORY}
            serverFeaturedOffers={serverFeaturedOffers}
            title="Ofertas Recomendadas"
          />
        </div>
        <div>
          <div
            className={twJoin(
              'grid grid-cols-4 gap-0 gap-x-4 md:grid-cols-12',
              activeOffersListTab.id !== HIGHLIGHTS_OFFERS &&
                smallAds.length !== SMALL_ADS_LENGTH_FOR_RENDER &&
                'pt-7 md:pt-0'
            )}
          >
            <OffersListSection
              cardsAds={cardsAds}
              cookieIsUserLogged={cookieIsUserLogged}
              cookieOffersViewMode={cookieOffersViewMode}
              gaEventCategory={GA_EVENT_CATEGORY}
              serverOffers={serverOffers}
            />
            <Sidebar
              gaEventCategory={GA_EVENT_CATEGORY}
              sidebarAd={sidebarAd}
              onShowFooterButtonClick={onShowFooterButtonClick}
            />
          </div>
        </div>
        <WishlistMaintenanceActionSheet />
      </Layout>
    </>
  );
};

HomePage.propTypes = {
  cardsAds: PropTypes.arrayOf(
    PropTypes.shape({
      bgcolor: PropTypes.arrayOf(PropTypes.string),
      description: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      image: PropTypes.string.isRequired,
      isClosable: PropTypes.bool.isRequired,
      mobileImage: PropTypes.string.isRequired,
      position: PropTypes.number.isRequired,
      storeSlug: PropTypes.string,
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  cookieIsUserLogged: PropTypes.bool.isRequired,
  cookieOffersViewMode: PropTypes.string,
  description: PropTypes.string,
  recoveryPassword: PropTypes.shape({
    recoveryHash: PropTypes.string.isRequired,
    userEmail: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired,
  }),
  serverFeaturedOffers: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number.isRequired,
      categorySlug: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      lastActiveOffer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      offerComments: PropTypes.number.isRequired,
      offerId: PropTypes.number.isRequired,
      offerIsHighlight: PropTypes.bool.isRequired,
      ratings: PropTypes.shape().isRequired,
      offerOldPrice: PropTypes.number,
      offerPhoto: PropTypes.string.isRequired,
      offerPrice: PropTypes.number.isRequired,
      offerPriceType: PropTypes.string.isRequired,
      offerPublished: PropTypes.string.isRequired,
      offerSlug: PropTypes.string.isRequired,
      offerStatusName: PropTypes.string.isRequired,
      offerTags: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
        })
      ),
      offerTitle: PropTypes.string.isRequired,
      pageTitle: PropTypes.string,
      storeDomain: PropTypes.string.isRequired,
      storeId: PropTypes.number,
      storeName: PropTypes.string,
      subcategoryId: PropTypes.number,
      subcategorySlug: PropTypes.string.isRequired,
      userId: PropTypes.number.isRequired,
      userLevel: PropTypes.number,
      userName: PropTypes.string.isRequired,
      userPhoto: PropTypes.string.isRequired,
      userTypeName: PropTypes.string.isRequired,
      userUsername: PropTypes.string.isRequired,
    })
  ).isRequired,
  serverOffers: PropTypes.shape({
    after: PropTypes.string.isRequired,
    before: PropTypes.number,
    offers: PropTypes.arrayOf(
      PropTypes.shape({
        categoryId: PropTypes.number,
        categoryName: PropTypes.string,
        categorySlug: PropTypes.string,
        key: PropTypes.string.isRequired,
        offerComments: PropTypes.number.isRequired,
        offerId: PropTypes.number.isRequired,
        offerIsHighlight: PropTypes.bool.isRequired,
        ratings: PropTypes.shape().isRequired,
        offerOldPrice: PropTypes.number,
        offerPhoto: PropTypes.string.isRequired,
        offerPrice: PropTypes.number.isRequired,
        offerPriceType: PropTypes.string.isRequired,
        offerPublished: PropTypes.string.isRequired,
        offerSlug: PropTypes.string.isRequired,
        offerStatusName: PropTypes.string.isRequired,
        offerTags: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
          })
        ),
        offerTitle: PropTypes.string.isRequired,
        storeDomain: PropTypes.string.isRequired,
        storeId: PropTypes.number,
        storeImage: PropTypes.string,
        storeName: PropTypes.string,
        subcategoryId: PropTypes.number,
        subcategoryName: PropTypes.string,
        subcategorySlug: PropTypes.string,
        userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        userName: PropTypes.string.isRequired,
        userPhoto: PropTypes.string.isRequired,
        userTypeName: PropTypes.string.isRequired,
        userUsername: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  sidebarAd: PropTypes.shape({
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  smallAds: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

export default HomePage;
